import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { Link } from 'react-router-dom';
import fy24_25a from '../../assests/sgPdf/ScheduleOfAnalyst/FY 2024-25/2Qfy25 Investors call.pdf';




const ScheduleOfAnalyst = () => {

    const [selectedOption, setSelectedOption] = useState("option"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Schedule of Analyst or Institutional Meet </h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Schedule of Analyst or Institutional Meet </h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option">FY 2024-2025</option>
                                

                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* 24-25 */}

                        {selectedOption === "option" && (
                            <>
                                
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25a} target='blank'>  <h6>2Qfy25 Investors call</h6> </a>
                                </div>
                                
                                


                            </>

                        )}
                       



                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}

export default ScheduleOfAnalyst